<template>
  <div class="legend-item">
    <div class="color-marker" :style="{ backgroundColor: colorHash }"></div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  name: 'LegendItem',
  props: {
    color: String,
    text: String,
  },
  setup(props) {
    const colorHash = computed(() => '#' + props.color);
    return { colorHash }
  },
}
</script>

<style lang="less" scoped>
.legend-item {
  display: inline-block;
  position: relative;
  padding: 0 0 0 18px;
  margin: 0 1rem 0.25rem 0;

  .color-marker {
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    left: 2px;
    top: 50%;
    margin-top: -0.25rem;
  }

  .text {
    font-size: 14px;
    color: @medium-gray;
  }
}
</style>
